import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { sizeMobile } from 'components/globalStyles'
import { fontClamp } from 'utils/textUtils'

export const StyledLink = styled(Link)`
  padding: ${props => (props.$small ? '1.2rem 1.2rem 1rem' : '1.6rem 2rem 1.3rem')};
  font-family: Garamond;
  font-weight: 600;
  font-size: ${props => (props.$small ? '1.2rem' : '1.4rem')};
  line-height: ${props => (props.$small ? '1.44rem' : '1.68rem')};
  letter-spacing: ${props => (props.$small ? '0.24rem' : '0.38rem')};
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  border: solid;
  border-width: ${props => (props.$small ? '1px' : '2px')};
  transition: all 0.4s;
  text-decoration: none;
  color: ${props => (props.$black || props.$white ? 'white' : 'inherit')};
  background-color: ${props => (props.$black ? 'var(--spBlack)' : 'transparent')};
  border-color: ${props => (props.$white ? 'white' : 'var(--spBlack)')};
  width: ${props => (props.$fullWidth ? '100%' : 'fit-content')};
  display: inline-block;

  &:hover {
    color: ${props => (props.$white ? 'var(--spBlack)' : 'white')};
    background-color: ${props => (props.$white ? 'white' : '#213E3C')};
    border-color: ${props => (props.$white ? 'white' : '#213E3C')};
  }

  &:disabled {
    color: ${props => (props.$black ? 'white' : props.$white ? 'var(--gray5)' : 'var(--gray1)')};
    background-color: ${props => (props.$black ? 'var(--gray1)' : 'transparent')};
    border-color: ${props => (props.$white ? 'var(--gray5)' : 'var(--gray1)')};
  }

  ${props => props.disabled && css`
    color: ${props.$black ? 'white' : props.$white ? 'var(--gray5)' : 'var(--gray1)'};
    background-color: ${props.$black ? 'var(--gray1)' : 'transparent'};
    border-color: ${props.$white ? 'var(--gray5)' : 'var(--gray1)'};
    pointer-events: none;
  `}

  @media (max-width: ${sizeMobile}) {
    width: 100%;
  }
`

export const StyledButton = styled(StyledLink).attrs(() => ({
  as: 'button'
}))`
`

export const H4Semi = styled.h4`
  font-weight: 600;
`

export const BodySemi = styled.p`
  font-weight: 600;
`

export const Caption1 = styled.p`
  font-family: Proxima;
  font-size: 1.4rem;
  line-height: 1.7rem;

  @media (max-width: ${sizeMobile}) {
    font-size: 1.6rem;
    line-height: 2rem;
  }
`

export const Caption2 = styled.p`
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.4rem;
`

export const Label = styled.p`
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.4rem;
  text-transform: uppercase;
`

export const MiniText = styled.p`
  font-weight: bold;
  font-size: 1rem;
`

export const LinkSmall = styled(Link)`
  & a {
    font-size: 1.6rem;
  }
`

export const Badge = styled.div`
  background-color: var(--forest);
  color: white;
  padding: 8px;
  font-family: Proxima;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.4rem;
  letter-spacing: normal;
  width: fit-content;
  text-transform: uppercase;
  white-space: nowrap;
`

export const RoundedBadge = styled(Badge)`
  background-color: white;
  color: var(--forest);
  padding: 6px 8px;
  border: 1px solid var(--forest);
  border-radius: 1.4rem;
  cursor: pointer;

  ${props => props.disabled && css`
    background-color: var(--gray5);
    color: var(--gray1);
    border-color: var(--gray5);
    pointer-events: none;
  `}

  ${props => props.selected && css`
    background-color: var(--forest);
    color: white;
  `}

  &:hover {
    background-color: var(--forest);
    color: white;
  }
`

export const UnderlineButton = styled.button`
  font-family: Proxima;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.95rem;
  letter-spacing: normal;
  text-decoration: underline;
  text-transform: none;
  border: none;
  background: transparent;
  margin: ${props => (props.noPadding ? '0' : '24px 0')};
  padding: 0;
`

const fontFamily = {
  h1: 'Garamond',
  h2: 'Garamond',
  h3: 'Garamond',
  h4: 'Garamond',
  h5: 'Proxima'
}

const fontWeight = {
  h1: 'normal',
  h2: 600,
  h3: 600,
  h4: 'normal',
  h5: 600
}

const fontSize = {
  h1: fontClamp(6.4, 9.2),
  h2: fontClamp(5.2, 6),
  h3: fontClamp(3.2, 3.6),
  h4: fontClamp(2.4, 3),
  h5: '2rem'
}

const lineHeight = {
  h1: '0.95em',
  h2: 'normal',
  h3: '4.2rem',
  h4: '3.6rem',
  h5: 'normal'
}

export const StyledH1 = styled.h1`
  font-family: ${props => fontFamily[props.variant]};
  font-weight: ${props => (props.semi ? '600' : fontWeight[props.variant])};
  font-size: ${props => fontSize[props.variant]};
  line-height: ${props => lineHeight[props.variant]};
`

export const StyledH2 = styled.h2`
  font-family: ${props => fontFamily[props.variant]};
  font-weight: ${props => (props.semi ? '600' : fontWeight[props.variant])};
  font-size: ${props => fontSize[props.variant]};
  line-height: ${props => lineHeight[props.variant]};
`
export const StyledH3 = styled.h3`
  font-family: ${props => fontFamily[props.variant]};
  font-weight: ${props => (props.semi ? '600' : fontWeight[props.variant])};
  font-size: ${props => fontSize[props.variant]};
  line-height: ${props => lineHeight[props.variant]};
`

export const StyledH4 = styled.h4`
  font-family: ${props => fontFamily[props.variant]};
  font-weight: ${props => (props.semi ? '600' : fontWeight[props.variant])};
  font-size: ${props => fontSize[props.variant]};
  line-height: ${props => lineHeight[props.variant]};
`

export const StyledH5 = styled.h5`
  font-family: ${props => fontFamily[props.variant]};
  font-weight: ${props => (props.semi ? '600' : fontWeight[props.variant])};
  font-size: ${props => fontSize[props.variant]};
  line-height: ${props => lineHeight[props.variant]};
`

export const StyledP = styled.p`
  font-family: ${props => fontFamily[props.variant]};
  font-weight: ${props => (props.semi ? '600' : fontWeight[props.variant])};
  font-size: ${props => fontSize[props.variant]};
  line-height: ${props => lineHeight[props.variant]};
`
