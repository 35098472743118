import CartContextContainer from 'components/booking/context/cartContext'
import LoginContextContainer from 'components/login/loginContext'
import StaylistFetchContextContainer from 'components/common/staylistFetchContext'
import AvailabilityContextContainer from 'components/booking/context/availabilityContext'

export const wrapRootElement = ({ element }) =>
  <StaylistFetchContextContainer>
    <LoginContextContainer>
      <CartContextContainer>
        <AvailabilityContextContainer>
          {element}
        </AvailabilityContextContainer>
      </CartContextContainer>
    </LoginContextContainer>
  </StaylistFetchContextContainer>
