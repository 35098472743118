export const textWidth = (textArray, font, rem, weight) => {
  if (typeof window === 'undefined' || typeof document === 'undefined') { return 0 }
  const pixelSize = rem * parseFloat(window.getComputedStyle(document.documentElement).fontSize)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  ctx.font = `${weight} ${pixelSize}px ${font}`
  return textArray.reduce((prev, cur) => Math.max(prev, Math.ceil(ctx.measureText(cur).width)), 0)
}

export const fontClamp = (remMin, remMax, viewportMin = 768, viewportMax = 1440) => {
  const vw = (remMax - remMin) / (viewportMax - viewportMin) * 1000
  const offset = vw / 1000 * viewportMin - remMin
  const sign = Math.sign(offset) === -1 ? '+' : '-'
  return `clamp(${remMin}rem, ${vw.toFixed(3)}vw ${sign} ${Math.abs(offset).toFixed(3)}rem, ${remMax}rem)`
}
