import styled from 'styled-components'
import { TransparentButton, FlexRow } from 'components/common/common_styles'
import { sizeMobile } from 'components/globalStyles'

export const MainContainer = styled.dialog`
    background: white;
    width: max-content;
    max-width: 75vw;
    max-height: 90vh;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 48px 80px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    text-align: center;
    white-space: normal;
    border: none;
    overflow-x: hidden;
    overflow-y: ${props => (props.scroll ? 'auto' : 'hidden')};
    overscroll-behavior: contain;

  &[open] {
    display: flex;
  }

  &::backdrop {
    background: rgba(108, 103, 98, 0.5);
  }

  @media (max-width: ${sizeMobile}) {
    padding: 24px 36px;
    gap: 16px;
    max-width: 90vw;
  }
`

export const CloseButtonContainer = styled(TransparentButton)`
  position: absolute;
  right: 12px;
  top: 12px;
  width: 18px;
`

export const ModalButtonRow = styled(FlexRow)`
  @media (max-width: ${sizeMobile}) {
    flex-direction: column;
    gap: 16px;
  }
`
