import { useState, useEffect, useRef, useCallback, createContext } from 'react'
import { folioIsPending } from 'components/booking/context/bookingUtils'
import useStaylistAPIs from 'components/booking/context/useStaylistAPIs'
import { removeFromStorage, objectToStorage, objectFromStorage } from 'utils/storageUtils'
import { now } from 'utils/dateUtils'
import ModalDialog from 'components/common/modalDialog'

export const CartContext = createContext()

const CartContextContainer = ({ children }) => {
  const [cart, setCart] = useState()
  const [cartFolio, setCartFolio] = useState()
  const [remainingTime, setRemainingTime] = useState(0) // in seconds
  const [showExpireDialog, setShowExpireDialog] = useState(false)
  const countdownTimer = useRef()
  const { getFolio } = useStaylistAPIs()

  const expired = dateString => new Date(dateString) <= now()

  const startCountdown = useCallback(expireTime => {
    const expirationTime = new Date(expireTime)
    clearInterval(countdownTimer.current)
    if (expired(expireTime)) { return }
    countdownTimer.current = setInterval(() => {
      const timeOffset = Math.floor((expirationTime - now()) / 1000)
      setRemainingTime(timeOffset)
      if (timeOffset <= 0) {
        clearCart()
        setShowExpireDialog(true)
      }
    }, 1000)
  }, [])

  const updateCart = useCallback(folio => {
    if (!folioIsPending(folio)) { return }

    const cartData = { folio_id: folio.folio_id, expires_on: folio.expires_on, itemCount: folio.reservations?.length ?? 0 }
    setCart(cartData)
    setCartFolio(folio)
    objectToStorage('cart', cartData, true)
    startCountdown(folio.expires_on)
  }, [startCountdown])

  useEffect(() => {
    let ignore = false

    const storedCart = objectFromStorage('cart', true)
    if (storedCart?.expires_on && expired(storedCart?.expires_on)) {
      clearCart()
    }
    else if (storedCart?.folio_id) {
      const updateFolio = async() => {
        const result = await getFolio(storedCart.folio_id, false) // don't show an error if we're at startup and this is a leftover folio
        if (result && !ignore) {
          updateCart(result)
        }
      }
      updateFolio()
    }

    return () => { ignore = true }
  }, [getFolio, updateCart])

  const clearCart = () => {
    clearInterval(countdownTimer.current)
    setCart()
    setCartFolio()
    setRemainingTime(0)
    removeFromStorage('cart', true)
  }

  return (
    <CartContext.Provider value={{
      cart,
      cartFolio,
      updateCart,
      clearCart,
      remainingTime
    }}
    >
      {children}
      <ModalDialog title='Your pending reservation has expired.' isOpen={showExpireDialog} onClose={() => setShowExpireDialog(false)}>
        <p>You have 15 minutes to complete a reservation.</p>
      </ModalDialog>
    </CartContext.Provider>
  )
}

export default CartContextContainer
