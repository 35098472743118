import { useRef, useEffect } from 'react'
import PulseLoader from 'react-spinners/PulseLoader'
import { MainContainer } from './loadingOverlay_styles'

const LoadingOverlay = ({ visible }) => {
  const timerRef = useRef()
  const dialogRef = useRef()

  useEffect(() => {
    clearTimeout(timerRef.current)
    if (visible) {
      timerRef.current = setTimeout(() => {
        if (!dialogRef.current?.open) {
          try {
            dialogRef.current?.showModal()
          }
          catch (error) {
            console.error(error)
          }
        }
      }, 500)
    }
    else {
      dialogRef.current?.close()
    }
  }, [visible])

  return (
    <MainContainer ref={dialogRef} aria-label='loading'>
      <PulseLoader
        loading={true}
        color='#F1EAE3'
      />
    </MainContainer>
  )
}

export default LoadingOverlay
