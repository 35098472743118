import { useRef, useEffect } from 'react'
import { ReactComponent as CloseButton } from 'svgs/CloseButton.svg'
import { StyledH2 } from 'components/common/typography'
import { MainContainer, CloseButtonContainer, ModalButtonRow } from './modalDialog_styles'

export { ModalButtonRow }

const ModalDialog = ({ title, children, scroll, isOpen, onClose }) => {
  const ref = useRef()

  useEffect(() => {
    try {
      if (isOpen) {
        ref.current?.showModal()
        document.body.classList.add('modal-open')
      }
      else {
        ref.current?.close()
        document.body.classList.remove('modal-open')
      }
    }
    catch (error) {
      if (process.env.LOG_ERRORS) {
        console.error(error)
      }
    }
  }, [isOpen])

  return (
    <MainContainer aria-label={title} ref={ref} scroll={scroll} onCancel={onClose}>
      {title && <StyledH2 variant='h3'>{title}</StyledH2>}
      {children}
      <CloseButtonContainer type='button' onClick={onClose} aria-label='close dialog' autoFocus> {/* eslint-disable-line */}
        <CloseButton viewBox='0 0 25 25' />
      </CloseButtonContainer>
    </MainContainer>
  )
}

export default ModalDialog
