import styled from 'styled-components'

export const MainContainer = styled.dialog`
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  border: none;

  &::backdrop {
    background: rgba(0, 0, 0, 0.5);
  }
`
