import { useState, useReducer, useEffect, createContext, useContext, useCallback } from 'react'
import { numberFromStorage, numberToStorage, removeFromStorage } from 'utils/storageUtils'
import { StaylistFetchContext } from 'components/common/staylistFetchContext'

export const LoginContext = createContext()

const userIdReducer = (_, action) => {
  if (action.type === 'clear') {
    removeFromStorage('userId')
    removeFromStorage('userId', true)
    return 0
  }
  else {
    numberToStorage('userId', action.id, action.type === 'persistent')
    return action.id
  }
}

const LoginContextContainer = ({ children }) => {
  const { fetchStaylistEndpoint } = useContext(StaylistFetchContext)
  const [userId, dispatchUserId] = useReducer(userIdReducer, +numberFromStorage('userId') || +numberFromStorage('userId', true))
  const [userDetails, setUserDetails] = useState()

  const fetchDetails = useCallback(async() => {
    if (!userId) {
      setUserDetails()
      return
    }
    const result = await fetchStaylistEndpoint({
      endpoint: 'contact/details',
      params: { id: userId },
      errorTitle: 'Unable to Retrieve User Details',
      showLoadingOverlay: false
    })
    if (result) {
      const rawResult = await fetch(process.env.GATSBY_KLAVIYO_SUBSCRIBE_API_ENDPOINT, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: result.email, action: 'read' })
      })
      const formattedResult = await rawResult.json()
      if (process.env.LOG_ERRORS && formattedResult.status === 'error') {
        console.error('Fetch Email Profile Klaviyo Error:', formattedResult.message)
      }
      setUserDetails({ ...result, receive_emails: formattedResult?.message?.data?.length > 0 })
    }
  }, [fetchStaylistEndpoint, userId])

  useEffect(() => {
    let ignore = false

    if (!ignore) {
      fetchDetails()
    }

    return () => { ignore = true }
  }, [userId, fetchDetails])

  const login = async(params) => {
    const persistent = params.persistent
    delete params.persistent

    const result = await fetchStaylistEndpoint({
      endpoint: 'contact/login',
      params,
      post: true,
      errorTitle: 'Unable to Log In'
    })
    if (result) {
      dispatchUserId({ id: +result.id, type: persistent ? 'persistent' : '' })
      return result
    }
  }

  const logout = () => {
    dispatchUserId({ type: 'clear' })
    setUserDetails()
  }

  const refresh = () => {
    fetchDetails()
  }

  const updateUserId = id => {
    dispatchUserId({ id: +id })
    fetchDetails()
  }

  return (
    <LoginContext.Provider value={{
      userDetails,
      login,
      logout,
      refresh,
      updateUserId
    }}
    >
      {children}
    </LoginContext.Provider>
  )
}

export default LoginContextContainer
