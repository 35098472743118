import styled, { css } from 'styled-components'
import { numberOrArray, booleanOrArray } from 'utils/numberUtils'
import { Swiper } from 'swiper/react'
import { sizeMobile, gutter, verticalSpacerDesktop, verticalSpacerMobile, maxContainerWidth, gridGap } from 'components/globalStyles'

export const ConditionalDiv = styled.div`
  display: ${ props => (props.mobileOnly ? 'none' : 'contents') };

  @media (max-width: ${sizeMobile}) {
    display: ${ props => (props.desktopOnly ? 'none' : 'contents') };
  }
`

export const MouseAvailable = styled.div`
  display: none;
  @media (pointer: fine) and (hover: hover) {
    display: contents;
  }
`

export const TouchAvailable = styled.div`
  display: none;
  @media (pointer: coarse) and (hover: none) {
    display: contents;
  }
`

export const FlexColumn = styled.div`
  width: ${props => (props.fit ? 'fit-content' : '100%')};
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.center ? 'center' : 'start')};
  gap: ${props => numberOrArray(props.gap, 0, 24)}px;

  @media (max-width: ${sizeMobile}) {
    gap: ${props => numberOrArray(props.gap, 1, 24)}px;
  }
`

export const FlexRow = styled(FlexColumn)`
  flex-direction: row;
`

export const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => (props.washi ? 'var(--washi)' : props.black ? 'var(--spBlack)' : 'white')};
  position: relative;
  transition: background-color 1.5s;
  z-index: ${props => (props.front ? 1 : 'auto')};

  & h2 {
    color: ${props => (props.washi ? 'var(--forest)' : 'var(--spBlack)')};
  }
`

export const AutoSwiper = styled(Swiper)`
  & .swiper-slide {
    width: auto;
    max-width: calc(100% - 64px);
  }
`

export const GridMixin = () => css`
  display: grid;
  justify-items: start;
  align-items: start;
  justify-content: center;
  align-content: start;
  width: fit-content;
  column-gap: ${gridGap};

  @media (max-width: ${sizeMobile}) {
    grid-template-columns: 1fr;
    width: 100%;
  }
`

export const FadeUpMixin = ({ delay }) => css`
  opacity: 0;
  transform: translateY(75px);
  transition: opacity 1s, transform 1s;
  transition-delay: ${delay ?? 0}s;
  ${props => props.inView && css`
    opacity: 1;
    transform: none;
  `}
`

export const BackgroundFill = styled.div`
  position: absolute;
  width: 100%;
  height: 48px;
  left: 0;
  top: ${props => (props.bottom ? 'auto' : 0)};
  bottom: ${props => (props.bottom ? 0 : 'auto')};
  background-color: ${props => (props.washi ? 'var(--washi)' : 'white')};
`

export const Spacer = styled.div`
  width: 100%;
  height: ${props => props.desktopHeight ?? 0}px;
  background-color: ${props => (props.washi ? 'var(--washi)' : props.black ? 'var(--spBlack)' : 'white')};

  @media (max-width: ${sizeMobile}) {
    height: ${props => props.mobileHeight ?? 0}px;
  }
`

export const PaddingContainer = styled.div`
  width: 100%;
  max-width: ${maxContainerWidth};
  padding-left: ${props => (booleanOrArray(props.padLeft, 0) ? gutter : 0)};
  padding-right: ${props => (booleanOrArray(props.padRight, 0) ? gutter : 0)};
  padding-top: ${props => numberOrArray(props.padTop, 0) * verticalSpacerDesktop}px;
  padding-bottom: ${props => numberOrArray(props.padBottom, 0) * verticalSpacerDesktop}px;
  background-color: ${props => (props.washi ? 'var(--washi)' : 'inherit')};

  @media (max-width: ${sizeMobile}) {
    padding-left: ${props => (booleanOrArray(props.padLeft, 1) ? gutter : 0)};
    padding-right: ${props => (booleanOrArray(props.padRight, 1) ? gutter : 0)};
    padding-top: ${props => numberOrArray(props.padTop, 1) * verticalSpacerMobile}px;
    padding-bottom: ${props => numberOrArray(props.padBottom, 1) * verticalSpacerMobile}px;
  }
`

export const TransparentButton = styled.button.attrs(() => ({
  type: 'button'
}))`
  padding: 0;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  text-transform: none;
  white-space: normal;
  border: none;
  background: none;
  display: block;
`
