import { useState, createContext, useCallback } from 'react'
import ModalDialog from 'components/common/modalDialog'
import LoadingOverlay from 'components/common/loadingOverlay'

export const StaylistFetchContext = createContext()

const StaylistFetchContextContainer = ({ children }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [loadingCount, setLoadingCount] = useState(0)
  const [modalParams, setModalParams] = useState({
    title: '',
    body: ''
  })

  const fetchStaylistEndpoint = useCallback(async({ endpoint, params, post = false, errorTitle = 'Error', showError = true, showLoadingOverlay = true }) => {
    if (!endpoint) { return }
    let loadingCountAdjusted = false

    try {
      if (showLoadingOverlay) {
        setLoadingCount(oldVal => oldVal + 1)
      }
      const rawResult = await fetch(process.env.GATSBY_STAYLIST_API_ENDPOINT, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ endpoint, params, post })
      })

      if (showLoadingOverlay) {
        loadingCountAdjusted = true
        setLoadingCount(oldVal => oldVal - 1)
      }

      if (!rawResult.ok) {
        process.env.LOG_ENDPOINTS && console.warn('endpoint:', endpoint, '  params:', params)
        setModalParams({
          title: 'Server Error',
          body: 'The server returned an error. Please try again in a few moments.'
        })
        if (showError) { setShowDialog(true) }
        return
      }
      const result = await rawResult.json()
      process.env.LOG_ENDPOINTS && console.log('endpoint:', endpoint, '  params:', params, ' result:', result)
      if (result.status === 'success') {
        return result.data
      }
      setModalParams({
        title: errorTitle,
        body: result?.data?.message ?? result?.message ?? 'Unspecified error'
      })
      if (showError) { setShowDialog(true) }
    }
    catch (error) {
      if (showLoadingOverlay && !loadingCountAdjusted) {
        setLoadingCount(oldVal => oldVal - 1)
      }
      setModalParams({
        title: 'Server Error',
        body: `${errorTitle}: ${error.message}`
      })
      if (showError) { setShowDialog(true) }
    }
  }, [])

  const fetchStripeConfirmPayment = useCallback(async(stripe, elements) => {
    if (!stripe || !elements) { return }

    try {
      setLoadingCount(oldVal => oldVal + 1)
      const result = await stripe.confirmPayment({ elements, redirect: 'if_required' })
      setLoadingCount(oldVal => oldVal - 1)
      if (result?.paymentIntent) {
        process.env.LOG_ENDPOINTS && console.log('endpoint: stripe.confirmPayment, result:', result?.paymentIntent)
      }
      return result
    }
    catch (error) {
      setModalParams({
        title: 'Server Error',
        body: error.message
      })
      setShowDialog(true)
    }
  }, [])

  return (
    <StaylistFetchContext.Provider value={{
      fetchStaylistEndpoint,
      fetchStripeConfirmPayment
    }}
    >
      {children}
      <ModalDialog title={modalParams.title} isOpen={showDialog} onClose={() => setShowDialog(false)}>
        <p>{modalParams.body}</p>
      </ModalDialog>
      <LoadingOverlay visible={loadingCount > 0 && !showDialog} />
    </StaylistFetchContext.Provider>
  )
}

export default StaylistFetchContextContainer
