import { createGlobalStyle } from 'styled-components'
import { fontClamp } from 'utils/textUtils'
import GaramondRegular from 'fonts/AGaramondPro-Regular.otf'
import GaramondItalic from 'fonts/AGaramondPro-Italic.otf'
import GaramondBold from 'fonts/AGaramondPro-Bold.otf'
import GaramondBoldItalic from 'fonts/AGaramondPro-BoldItalic.otf'
import GaramondSemibold from 'fonts/AGaramondPro-Semibold.otf'
import GaramondSemiboldItalic from 'fonts/AGaramondPro-SemiboldItalic.otf'
import ProximaRegular from 'fonts/ProximaNova-Regular.otf'
import ProximaLight from 'fonts/ProximaNova-Light.otf'
import ProximaBold from 'fonts/ProximaNova-Bold.otf'
import ProximaSemibold from 'fonts/ProximaNova-Semibold.otf'
import ProximaThinItalic from 'fonts/ProximaNova-ThinItalic.otf'
import Berthold from 'fonts/BertholdAkzidenzGroteskBEBo.ttf'

export const sizeTiny = '320px'
export const sizeMobile = '767px'
export const sizeMedium = '1279px'
export const maxContainerWidth = '1700px'

export const gutter = 'clamp(24px, 7.1429vw - 22.86px, 80px)' // 32px at 768, 80px at 1440
export const negativeGutter = `calc(0px - ${gutter})` // this is the only style that works for the negative of a clamp
export const gridGap = 'clamp(32px, 3.274vw + 16.857px, 64px)' // 42px at 768, 64px at 1440

export const verticalSpacerDesktop = 32
export const verticalSpacerMobile = 24

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
    scroll-padding-top: 120px;
  }

  html, body {
    height: 100%;
  }

  body {
    cursor: default;
    font-family: Proxima;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 1.5em;
    color: var(--spBlack);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.modal-open {
      overflow: hidden;
    }

    @media (max-width: ${sizeMobile}) {
      font-size: 1.8rem;
    }
  }

  main {
    width: 100%;
    flex: 1;
  }

  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }

  input, button, textarea, select {
    font: inherit;
  }

  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }

  h1, h2, h3, h4, h5, h6 {
    line-height: 1.15em;
  }

  p {
    font-family: Proxima;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 1.5em;
  }

  h1 {
    font-family: Garamond;
    font-weight: normal;
    font-size: ${fontClamp(6.4, 9.2)};
    line-height: 0.95em;
  }

  h2 {
    font-family: Garamond;
    font-weight: 600;
    font-size: ${fontClamp(5.2, 6)};
  }

  h3 {
    font-family: Garamond;
    font-weight: 600;
    font-size: ${fontClamp(3.2, 3.6)};
    line-height: 4.2rem;
  }

  h4 {
    font-family: Garamond;
    font-weight: normal;
    font-size: ${fontClamp(2.4, 3)};
    line-height: 3.6rem;

    @media (max-width: ${sizeTiny}) {
      font-size: 2rem;
    }
  }

  h5 {
    font-family: Proxima;
    font-weight: 600;
    font-size: 2rem;
  }

  button {
    cursor: pointer;
    padding: 1.6rem 2rem 1.3rem;
    font-family: Garamond;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.68rem;
    letter-spacing: 0.38rem;
    text-transform: uppercase;
    white-space: nowrap;
    border: solid;
    border-width: 2px;
    transition: all 0.4s;
    color: var(--spBlack);
  }

  ul {
    list-style-type: none;
  }

  a {
    color: var(--spBlack);
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2.2rem;
    width: fit-content;
  }

  address {
    font-style: normal;
  }

  select {
    color: var(--spBlack);
    font-family: Proxima;
  }

  input[type='checkbox'] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid var(--spBlack);
    display: grid;
    place-content: center;

    &:checked {
      background: var(--spBlack);

      &::after {
        content: '✓';
        color: white;
        grid-area: 1 / -1;
        font-size: 2rem;
        font-weight: 600;
        transform: translateY(2px);
      }
    }
  }

  #root {
    isolation: isolate;
  }

  :root {
    --spBlack: #171717;
    --forest: #063B27;
    --gray0: #4f4b48;
    --gray1: #6C6762;
    --gray2: #8D8780;
    --gray3: #C7C0BA;
    --gray4: #E0DCD9;
    --gray5: #F1EEEC;
    --green: #336061;
    --washi: #F1EAE3;
    --swiper-pagination-color: var(--forest);
  }

  @font-face {
    font-family: Garamond;
    src: url(${GaramondRegular});
    font-display: block;
  }

  @font-face {
    font-family: Garamond;
    font-style: italic;
    src: url(${GaramondItalic});
    font-display: block;
  }

  @font-face {
    font-family: Garamond;
    font-weight: bold;
    src: url(${GaramondBold});
    font-display: block;
  }

  @font-face {
    font-family: Garamond;
    font-style: italic;
    font-weight: bold;
    src: url(${GaramondBoldItalic});
    font-display: block;
  }

  @font-face {
    font-family: Garamond;
    font-weight: 600;
    src: url(${GaramondSemibold});
    font-display: block;
  }

  @font-face {
    font-family: Garamond;
    font-style: italic;
    font-weight: 600;
    src: url(${GaramondSemiboldItalic});
    font-display: block;
  }

  @font-face {
    font-family: Proxima;
    src: url(${ProximaRegular});
    font-display: block;
  }

  @font-face {
    font-family: Proxima;
    font-weight: bold;
    src: url(${ProximaBold});
    font-display: block;
  }

  @font-face {
    font-family: Proxima;
    font-weight: 600;
    src: url(${ProximaSemibold});
    font-display: block;
  }

  @font-face {
    font-family: Proxima;
    font-weight: 300;
    src: url(${ProximaLight});
    font-display: block;
  }

  @font-face {
    font-family: Proxima;
    font-weight: 100;
    font-style: italic;
    src: url(${ProximaThinItalic});
    font-display: block;
  }

  @font-face {
    font-family: Berthold;
    src: url(${Berthold});
    font-display: block;
  }
`

export default GlobalStyles
