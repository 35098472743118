export const numberOrArray = (input, index, fallback = 0) => {
  if (typeof input === 'number') {
    return input
  }
  if (Array.isArray(input) && input.length >= index + 1) {
    return input[index]
  }
  return fallback
}

export const booleanOrArray = (input, index, fallback = false) => {
  if (typeof input === 'boolean') {
    return input
  }
  if (Array.isArray(input) && input.length >= index + 1) {
    return input[index]
  }
  return fallback
}

export const clamp = (input, min, max) => {
  if (input < min) { return min }
  if (input > max) { return max }
  return input
}