import { Link } from 'gatsby'
import { StyledLink, StyledButton, H4Semi, BodySemi, Caption1, Caption2, Label, MiniText, LinkSmall, Badge, RoundedBadge, UnderlineButton, StyledH1, StyledH2, StyledH3, StyledH4, StyledH5, StyledP } from './typography_styles'

export { H4Semi, BodySemi, Caption1, Caption2, Label, MiniText, LinkSmall, StyledButton, Badge, RoundedBadge, UnderlineButton }
export { StyledH1, StyledH2, StyledH3, StyledH4, StyledH5, StyledP }

export const LinkButton = props => {
  if (!props.link) {
    return null
  }

  if (props.link?.startsWith('http')) {
    return (
      <StyledLink as='a' href={props.link} {...props} target='_blank' rel='noreferrer'>
        {props.children}
      </StyledLink>
    )
  }

  const formattedLink = props.link?.startsWith('/') ? props.link : `/${props.link}`
  return (
    <StyledLink to={formattedLink} {...props}>
      {props.children}
    </StyledLink>
  )
}

export const GeneralLink = ({ link, children, noUnderline = false, ...rest }) => {
  if (link?.startsWith('http')) {
    return (
      <a href={link} target='_blank' rel='noreferrer' style={{ textDecoration: noUnderline ? 'none' : 'underline' }} {...rest}>{children}</a>
    )
  }
  else if (link) {
    const formattedLink = link?.startsWith('/') ? link : `/${link}`
    return (
      <Link to={formattedLink} style={{ textDecoration: noUnderline ? 'none' : 'underline' }} {...rest}>{children}</Link>
    )
  }
  else {
    return (
      <>{children}</>
    )
  }
}
