const storageType = persistent => (persistent ? 'localStorage' : 'sessionStorage')

export const stringFromStorage = (key, persistent = false) => {
  if (typeof window === 'undefined') { return }
  return window[storageType(persistent)].getItem(key)
}

export const numberFromStorage = (key, persistent = false, fallback = 0) => {
  if (typeof window === 'undefined') { return fallback }
  return Number(window[storageType(persistent)].getItem(key) ?? fallback)
}

export const boolFromStorage = (key, persistent = false) => {
  if (typeof window === 'undefined') { return false }
  return window[storageType(persistent)].getItem(key) === 'true'
}

export const objectFromStorage = (key, persistent = false) => {
  if (typeof window === 'undefined') { return }
  const savedObject = window[storageType(persistent)].getItem(key)
  if (!savedObject) { return }
  return JSON.parse(savedObject)
}

export const dateFromStorage = (key, persistent = false) => {
  if (typeof window === 'undefined') { return }
  const savedDate = window[storageType(persistent)].getItem(key)
  return savedDate ? new Date(JSON.parse(savedDate)) : undefined
}

export const stringToStorage = (key, item, persistent = false) => {
  if (typeof window === 'undefined') { return }
  window[storageType(persistent)].setItem(key, item)
}

export const numberToStorage = (key, item, persistent = false) => {
  if (typeof window === 'undefined') { return }
  window[storageType(persistent)].setItem(key, item ?? 0)
}

export const boolToStorage = (key, item, persistent = false) => numberToStorage(key, item, persistent)

export const objectToStorage = (key, item, persistent = false) => {
  if (!item || typeof window === 'undefined') { return }
  window[storageType(persistent)].setItem(key, JSON.stringify(item))
}

export const dateToStorage = (key, item, persistent = false) => objectToStorage(key, item, persistent)

export const removeFromStorage = (key, persistent = false) => {
  if (typeof window === 'undefined') { return }
  window[storageType(persistent)].removeItem(key)
}
